export default [
  {
    path: '/scanner',
    name: 'scanner',
    component: () => import('@/views/pages/management/scanner/Index.vue'),
    meta: {
      pageTitle: '스캐너 페이지',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/popopo',
    name: 'popopo',
    component: () => import('@/views/pages/management/scanner/popop.vue'),
    meta: {
      pageTitle: '주소',
      resource: 'Auth',
      action: 'read',
    },
  },
]
