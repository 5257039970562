export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/pages/management/users/users/Index.vue'),
    meta: {
      pageTitle: '회원 조회',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/users/:id',
    name: 'userShow',
    component: () => import('@/views/pages/management/users/users/Show.vue'),
    meta: {
      pageTitle: '회원 정보',
      resource: 'Auth',
      action: 'read',
    },
  },
]
