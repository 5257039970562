export default [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      // action: 'read',    // action = 'read' 는 생략가능
      resource: 'home',
    },
  },
]