import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import home from './routes/home'
import pages from './routes/pages'
import users from './routes/management/users'
import scanner from './routes/management/scanner'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...home,
    ...pages,
    ...users,
    ...scanner,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }else {
    if (to.name === 'auth-login') {
      return next();
    } else {
      if (!isLoggedIn) return next({ name: 'auth-login' });
    }
  }


  if(useJwt.getToken() && useJwt.getToken() != null){
    // console.log('f',useJwt.getToken())
    // console.log('f',)
    return new Promise((resolve, reject) => {
      axios
        .post('/jwt/refresh-token', {
          accessToken : useJwt.getToken() ,
          refreshToken : useJwt.getRefreshToken() 
        })
        .then(function(response){
          // console.log(response)
          if(response.data.result !=true){
            useJwt.setToken() 
            useJwt.setRefreshToken() 
            // console.log('hello2')
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

            // Remove userData from localStorage
            localStorage.removeItem('userData')
            // this.$ability.update(initialAbility)
            return next({ name: 'auth-login' })
            // resolve(next({ name: 'auth-login' }))
            // resolve(next())
          }else{
            useJwt.setToken(response.data.accessToken)
            useJwt.setRefreshToken(response.data.refreshToken) 
            return next()
          }
        } )
        .catch(error => reject(error))
    })
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
