const state = {
  showBlockUI: false,
  blockUIInfo: {
    message: "상품 데이터 불러오기 중...",
    showButton: false,
    unauthenticated: false,
  },
  isError: false,
  errorInfo: {},
  showConfirmUI: false,
  confirmUIInfo: {
    type: "alert",
    title: "",
    content: "",
    confirm_action: null,
    cancel_action: null,
    theme: "",
  },
  showToast: false,
  showToastInfo: {
    title: "Error",
    icon: "AlertTriangleIcon",
    variant: "danger",
  },
};

const getters = {
  getShowToast: (state) => {
    return state.showToast;
  },
  getShowToastInfo: (state) => {
    return state.showToastInfo;
  },
  getBlockUIVisible: (state) => {
    return state.showBlockUI;
  },
  getBlockUIMessage: (state) => {
    return state.blockUIInfo.message;
  },
  getBlockUIButtonVisible: (state) => {
    return state.blockUIInfo.showButton;
  },
  getBlockUIUnauthenticated: (state) => {
    return state.blockUIInfo.unauthenticated;
  },
  getBlockUIErrorVisible: (state) => {
    return state.isError;
  },
  getBlockUIErrorInfo: (state) => {
    return state.errorInfo;
  },
  getConfirmUIVisible: (state) => {
    return state.showConfirmUI;
  },
  getConfirmUIInfo: (state) => {
    return state.confirmUIInfo;
  },
};

const mutations = {
  setShowToast: (
    state,
    data = {
      show: false,
      info: { title: "Error", icon: "AlertTriangleIcon", variant: "danger" },
    }
  ) => {
    state.showToastInfo.title = data.info?.title || "Error";
    state.showToastInfo.icon = data.info?.icon || "AlertTriangleIcon";
    state.showToastInfo.variant = data.info?.variant || "danger";
    state.showToast = data.show;
  },
  showBlockUI: (
    state,
    message = "상품 데이터 불러오기 중...",
    showButton = false,
    unauthenticated = false
  ) => {
    state.isError = false;
    state.errorInfo = {};
    state.blockUIInfo.showButton = showButton;
    state.blockUIInfo.message = message;
    state.blockUIInfo.unauthenticated = unauthenticated;
    state.showBlockUI = true;
  },
  showBlockUIObject: (
    state,
    data = {
      message: "상품 데이터 불러오기 중...",
      showButton: false,
      unauthenticated: false,
    }
  ) => {
    state.isError = false;
    state.errorInfo = {};
    state.blockUIInfo.showButton = data?.showButton || false;
    state.blockUIInfo.message = data?.message || false;
    state.blockUIInfo.unauthenticated = data?.unauthenticated || false;
    state.showBlockUI = true;
  },
  hideBlockUI: (state) => {
    state.showBlockUI = false;
    state.blockUIInfo.showButton = false;
    state.blockUIInfo.unauthenticated = false;
    state.blockUIInfo.message = "상품 데이터 불러오기 중...";
    state.isError = false;
    state.errorInfo = {};
  },
  showErrorBlockUI: (state, errorInfo = {}) => {
    state.isError = true;
    state.errorInfo = errorInfo;
    state.showBlockUI = true;
  },
  setBlockUIMessage: (state, value) => {
    state.blockUIInfo.message = value;
  },
  setBlockUIButtonVisible: (state, value) => {
    state.blockUIInfo.showButton = value;
  },
  setBlockUIUnauthenticated: (state, value) => {
    state.blockUIInfo.unauthenticated = value;
  },
  setBlockUIErrorVisible: (state, value) => {
    state.isError = value;
  },
  setBlockUIErrorInfo: (state, value) => {
    state.erroInfo = value;
  },
  showConfirmUI: (state, type, data = {}) => {
    state.confirmUIInfo.type = type ?? "alert";
    state.confirmUIInfo.title = data.title ?? "Title";
    state.confirmUIInfo.content = data.content ?? "Content";
    state.confirmUIInfo.theme = data.theme ?? "primary";
    if (data && data.confirm_action)
      state.confirmUIInfo.confirm_action = data.confirm_action;
    else state.confirmUIInfo.confirm_action = null;
    if (data && data.cancel_action)
      state.confirmUIInfo.cancel_action = data.cancel_action;
    else state.confirmUIInfo.cancel_action = null;

    state.showConfirmUI = true;
  },
  hideConfirmUI: (state) => {
    state.confirmUIInfo.type = "alert";
    state.showConfirmUI = false;
  },
};

export default {
  state,
  mutations,
  getters,
};
